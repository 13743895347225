<template>
  <div>
    <button @click="openApp">测试1</button>
  </div>
</template>
<script>
import CallApp from 'callapp-lib'
// import device from '@/tools/device'
export default {
  name: 'Test',
  mixins: [],
  components: {
  },
  props: {},
  data () {
    return {

    }
  },
  methods: {
    openApp (url) {
      // window.location.href = 'syzjpro://'
      const options = {
        scheme: {
          protocol: 'syzjpro://'
        },
        intent: {
          package: '',
          scheme: ''
        },
        appstore: 'https://itunes.apple.com/cn/app/id1383186862',
        yingyongbao: '填写应用宝的下载地址',
        fallback: 'www.baidu.com'
      }
      const callLib = new CallApp(options)
      callLib.open({
        param: '',
        path: ''
      })
    }
  }
}
</script>
<style lang="css" scoped>
.mr-5 {
  margin-right: 5px;
}
.c-9 {
  color: #999999;
}
.fs-12 {
  font-size: 12px;
}
</style>
